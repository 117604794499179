import React, {useEffect} from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function Edit() {
    const navigate = useNavigate();
    const [formData, setFormData] = React.useState({
        'name': '',
        'email': '',
        'password': '',
        'update_password': ''
    })
    const studentId = window.location.search.split('studentId=')[1]
    const getData = async () => {
        const url = `/editStudentDetail`;
        axios.get(url, {
            params: {
                studentId: studentId,
            },
        }).then(res => {
            const data = res.data[0];
            setFormData({
                'name': data.name,
                'email': data.email,
                'password': data.password,
                'update_password': ''

            })
        }).catch(err => {
            console.log(err);
        })
    };
    useEffect(() => {
        getData();
    }, []);

    return(
        <section className="vh-100 bg-image" style={{backgroundImage: `url("https://mdbcdn.b-cdn.net/img/Photos/new-templates/search-box/img4.webp")`}}>
            <div className="mask d-flex align-items-center h-100 gradient-custom-3">
                <div className="container h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col-12 col-md-9 col-lg-7 col-xl-6">
                            <div className="card" style={{borderRadius: "15px"}}>
                                <div className="card-body p-5">
                                    <h2 className="text-uppercase text-center mb-5">Edit</h2>
                                    <form>
                                        <div className="form-outline mb-4">
                                            <input name="name" value={formData.name} onChange={handleChange} type="text" id="form3Example1cg" placeholder={"Your Name"}
                                                   className="form-control form-control-lg"/>
                                        </div>
                                        <div className="form-outline mb-4">
                                            <input name="email" value={formData.email} onChange={handleChange} type="email" id="form3Example3cg" placeholder={"Your Email"}
                                                   className="form-control form-control-lg"/>
                                        </div>
                                        <div className="form-outline mb-4">
                                            <input name="password" value={formData.password} disabled id="form3Example4cg" placeholder={"Old Password"}
                                                   className="form-control form-control-lg"/>
                                        </div>
                                        <div className="form-outline mb-4">
                                            <input name="update_password" value={formData.update_password} onChange={handleChange} id="form3Example4cdg" placeholder={"Update your password"}
                                                   className="form-control form-control-lg"/>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <button onClick={updateStudent} type="button"
                                                    className="btn btn-success btn-block btn-lg gradient-custom-4 text-body">Update
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
    function handleChange(event) {
        const {name, type, value, checked} = event.target
        setFormData(prevState => {
            return {
                ...prevState,
                [name]: type === 'checkbox' ? checked : value
            }
        })
    }
    function updateStudent() {
        if (studentId !== 0) {
            const updatedPassword = formData.update_password ? formData.update_password : formData.password
            const url = `/updateStudent`;
            axios.put(url, {
                params: {
                    studentId: studentId,
                    name: formData.name,
                    email: formData.email,
                    password: formData.password,
                    update_password: updatedPassword,
                },
            }).then(res => {
                alert('Updated Successfully')
                navigate('/dashboard')
            }).catch(err => {
                console.log(err);
            })
        } else {
            alert('Missing StudentId')
            return false
        }
    }
}

export default Edit;
