import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./../css/StudentDashboard.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import ReactPaginate from "react-paginate";
import PreventGoingBack from "./PreventGoingBack";
import CountdownTimer from "../../hooks/CountdownTimer";
import "../../hooks/Timer.css";
import { MathComponent } from "mathjax-react";
import { MathJax, MathJaxContext } from "better-react-mathjax";
import "./style.css";
import Nav from "../login/Nav";
import logo from "../../images/sindhya_logo.svg";
import $ from "jquery";
function PracticeTest() {
  const location = useLocation();
  const navigate = useNavigate();
  const stateData = location.state;
  const ONE_HOUR_IN_MS = 1 * 60 * 60 * 1000;
  const NOW_IN_MS = new Date().getTime();

  const [count, setCount] = React.useState(false);
  const [timer, setTimer] = React.useState(NOW_IN_MS + ONE_HOUR_IN_MS);
  const [questions, setQuestions] = useState([]);
  const [questionNo, setQuestionNo] = useState(1);
  const [displayAnswer, setDisplayAnswer] = useState();
  const [pageCount, setPageCount] = useState([]);
  const [ansSelected, setAnsSelected] = useState([]);
  const [formData, setFormData] = React.useState({
    answer: "",
  });
  const pageLimit = 1;
  const getQuestions = async (data) => {
    $(`.options .checkmark`).css({ background: "#a3a3a3" });
    $(".card-body").removeClass("ans-success ans-wrong");
    $(".q-opt-wrap .card.mb-3").css({ "pointer-events": "auto" });
    $(".spanAnswer:not([class*='d-none'])").addClass("d-none");
    var currentPage = data ? data.selected + 1 : 1;
    if (typeof data?.target !== "undefined") {
      currentPage = parseInt(data.target.getAttribute("value"));
    }
    const scrollElement = document.querySelector(".practice-test .questionBox");
    scrollElement.scrollTop -= 500; //scrollUp

    if (!isNaN(currentPage)) {
      document.getElementsByClassName("loader")[0].style.display = "block";
      setFormData({ answer: false });
      setDisplayAnswer(null);
      setQuestionNo(currentPage);
      const url = `/practiceQuestions?_page=${currentPage}&_limit=${pageLimit}`;

      axios
        .get(url, {
          params: {
            subChapterId: stateData.subid,
            chapterId: stateData.chapterId,
            subjectId: stateData.subjectId,
          },
        })
        .then((res) => {
          const result = res.data[0];
          setQuestions(result.data[0]);
          setPageCount(Math.ceil(result.count / pageLimit));
          document.getElementsByClassName("loader")[0].style.display = "none";
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  useEffect(() => {
    PreventGoingBack();
    getQuestions();
  }, []);
  useEffect(() => {
    $(function () {
      $('.navbar-sidenav [data-toggle="tooltip"]').tooltip({
        template:
          '<div className="tooltip navbar-sidenav-tooltip" role="tooltip" style="pointer-events: none;"><div className="arrow"></div><div className="tooltip-inner"></div></div>',
      });
      // // Toggle the side navigation
      $("#sidenavToggler").click(function (e) {
        e.preventDefault();
        $(".body").toggleClass("sidenav-toggled");
        if ($("div").hasClass("dashboard-nav")) {
          $(".body").toggleClass("sidenav-toggled");
        }
        $(".navbar-sidenav .nav-link-collapse").addClass("collapsed");
        $(
          ".navbar-sidenav .sidenav-second-level, .navbar-sidenav .sidenav-third-level"
        ).removeClass("show");
      });
      // // Force the toggled class to be removed when a collapsible nav link is clicked
      $(".navbar-sidenav .nav-link-collapse").click(function (e) {
        e.preventDefault();
        $(".body").removeClass("sidenav-toggled");
      });
      // // Prevent the content wrapper from scrolling when the fixed side navigation hovered over
      $(
        ".body.fixed-nav .navbar-sidenav, .body.fixed-nav .sidenav-toggler, .body.fixed-nav .navbar-collapse"
      ).on("mousewheel DOMMouseScroll", function (e) {
        var e0 = e.originalEvent,
          delta = e0.wheelDelta || -e0.detail;
        this.scrollTop += (delta < 0 ? 1 : -1) * 30;
        e.preventDefault();
      });
      // // Scroll to top button appear
      $(document).scroll(function () {
        var scrollDistance = $(this).scrollTop();
        if (scrollDistance > 100) {
          $(".scroll-to-top").fadeIn();
        } else {
          $(".scroll-to-top").fadeOut();
        }
      });
      // Configure tooltips globally
      $('[data-toggle="tooltip"]').tooltip();
      // Smooth scrolling using jQuery easing
      $(document).on("click", "a.scroll-to-top", function (event) {
        var $anchor = $(this);
        $("html, .body")
          .stop()
          .animate(
            {
              scrollTop: $($anchor.attr("href")).offset().top,
            },
            1000,
            "easeInOutExpo"
          );
        event.preventDefault();
      });
    });
  }, []);

  useEffect(() => {
    renderTD();
  }, [pageCount]);

  useEffect(() => {
    ansSelected.map((selected) => {
      var element = document.getElementById(selected);
      element.classList.add("boxColor");
      element.classList.remove("bg-danger");
    });
  }, [ansSelected]);

  const submitAnswer = async (data) => {
    const url = `/submitPracticeQuestions`;
    axios
      .put(url, {
        params: {
          subChapterId: stateData.subid,
          email: stateData.email,
        },
      })
      .then((response) => {
        alert("Submited Successfully");
        response.status === 200
          ? navigate(`/subChapter?cid=${response.data[0].chapter_id}`, {
              state: {
                email: stateData.email,
                subChapterId: stateData.subjectId,
                chapterName: stateData.subject,
                studentId: stateData.studentId,
                studentData: stateData.studentData,
              },
            })
          : alert("Error Submitting Answer");
        return false;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (displayAnswer) {
      const scrollElement = document.querySelector(
        ".practice-test .questionBox"
      );
      scrollElement.scrollTop += 500; //scrollDown
    }
  }, [displayAnswer]);

  function missingQuestions(event) {
    var pageLimit = 1;
    var currentPage = parseInt(event.target.getAttribute("value"));
    setFormData({ answer: false });
    setDisplayAnswer(null);
    setQuestionNo(currentPage);
    const url = `http://localhost:3000/practiceQuestions?_page=${currentPage}&_limit=${pageLimit}`;

    axios
      .get(url, {
        params: {
          subChapterId: stateData.subid,
        },
      })
      .then((res) => {
        const result = res.data[0];
        setQuestions(result.data[0]);
        setPageCount(Math.ceil(result.count / pageLimit));
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleChange(event) {
    const { name, type, value, checked } = event.target;
    $(`.options .checkmark`).css({ background: "#a3a3a3" });
    $(`#answer-${value} .options .checkmark`).css({ background: "#6cc4ed" });
    if (!ansSelected.includes(`question-${questionNo}`)) {
      setAnsSelected([...ansSelected, `question-${questionNo}`]);
    }
    setDisplayAnswer(null);
    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: type === "checkbox" ? checked : value,
      };
    });
    $(".next-page").addClass("invisible");
    $("#myButton").removeClass("d-none");
    const div = document.getElementById("myButton");
    div.innerHTML = "CHECK";
  }

  function displayCorrectAnswer() {
    $(".q-opt-wrap .card.mb-3").css({ "pointer-events": "none" });
    if (formData.answer === questions.answer) {
      $(`#answer-${questions.answer}`).addClass("ans-success");
    } else if (formData.answer !== questions.answer) {
      $(`#answer-${formData.answer}`).addClass("ans-wrong");
      $(`#answer-${questions.answer}`).addClass("ans-success");
    }
    const wrongAns = document.getElementById(`spanAnswer-${formData.answer}`);
    wrongAns.classList.remove("d-none");
    wrongAns.style.color = "red";
    wrongAns.innerHTML = "Your Answer";
    const correctAns = document.getElementById(
      `spanAnswer-${questions.answer}`
    );
    correctAns.classList.remove("d-none");
    correctAns.style.color = "green";
    correctAns.innerHTML = "Right Answer";
    $(`#answer-${formData.answer} .options .checkmark`).css({
      background: "red",
    });
    $(`#answer-${questions.answer} .options .checkmark`).css({
      background: "green",
    });

    $("#myButton").addClass("d-none");
    $(".next-page").removeClass("invisible");
    const content = `<div class="answerBoxSolution">
     Solution</div>
      <div> ${questions.solution}
            </div>`;
    return setDisplayAnswer(content);
  }

  const renderTD = () => {
    let tableRow, tableColumn, table_text;
    let page_length = parseInt(pageCount);
    let rows = page_length / 10;
    let cell = 1;
    let tr = [];
    // if (page_length >= 10) {
    let tableElement = document.createElement("div");
    tableElement.className = "answered-table";
    for (let i = 0; i < page_length; i++) {
      tableRow = document.createElement("div");
      // for (let j = 0; j < 10; j++) {
      tableRow.setAttribute("class", "question_no");
      tableColumn = document.createElement("span");
      tableColumn.setAttribute("value", cell);
      tableColumn.setAttribute("id", "question-" + cell);
      tableColumn.setAttribute("class", "text-white cursor-pointer");
      tableColumn.setAttribute(
        "style",
        "cursor: pointer;border: 1px solid #ddd;"
      );
      table_text = document.createTextNode(cell);
      tableColumn.appendChild(table_text);
      tableRow.appendChild(tableColumn);
      tableRow.onclick = getQuestions;
      // if (cell === page_length) {
      //     break;
      // }
      cell++;
      // }
      tableElement.appendChild(tableRow);
      tr.push(tableElement);
    }
    // } else if (page_length < 10) {
    //     let tableElement = document.createElement("div");
    //     tableElement.className = "answered-table"
    //     tableRow = document.createElement("div");
    //     tableRow.setAttribute('class', 'question_no')
    //     for (let j = 0; j < page_length; j++) {
    //         tableColumn = document.createElement("span");
    //         tableColumn.setAttribute('value', cell)
    //         tableColumn.setAttribute('id', 'question-' + cell)
    //         tableColumn.setAttribute('class', 'text-white')
    //         table_text = document.createTextNode(cell);
    //         tableColumn.appendChild(table_text);
    //         tableRow.appendChild(tableColumn);
    //         tableRow.onclick = getQuestions
    //         if (cell === page_length) {
    //             break;
    //         }
    //         cell++;
    //     }
    //     tableElement.appendChild(tableRow);
    //     tr.push(
    //         tableElement
    //     )
    // }
    if (tr.length > 0) {
      document.getElementById("questions-list").append(tr[0]);
    }
  };
  function mobileMenuClick() {
    $("#navbarResponsive").toggleClass("show");
  }

  return (
    <div className="container fixed-sn cyan-skin practice-test-qno ">
      <nav className="navbar navbar-expand-lg fixed-top" id="mainNav">
        <Link className="navbar-brand">
          <img src={logo} alt="logo" height="40" />
        </Link>
        <button
          onClick={mobileMenuClick}
          className="navbar-toggler navbar-toggler-right"
          type="button"
          data-toggle="collapse"
          data-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul
            className="navbarsize navbar-nav navbar-sidenav "
            id="exampleAccordion"
          >
            <li className="heading">
              <br></br>
              Practice Session
            </li>
            <ul className="navbar-nav ">
              <li className="nav-item ">
                <button
                  onClick={submitAnswer}
                  id="end-practice"
                  className="btn practice-test endButton"
                  type="submit"
                >
                  End Practice
                </button>
              </li>
            </ul>
            <li
              className="nav-item questionBox"
              data-placement="right"
              title="Test"
            >
              <br></br>
              <div className="" id="questions-list"></div>
            </li>
          </ul>
          {/*<ul className="navbar-nav sidenav-toggler navbarsize">*/}
          {/*    <li className="nav-item">*/}
          {/*        <a className="nav-link text-center" id="sidenavToggler">*/}
          {/*            <i className="fa fa-fw fa-angle-left"></i>*/}
          {/*        </a>*/}
          {/*    </li>*/}
          {/*</ul>*/}
          <ul className="navbar-nav logo">
            <li className="nav-item ">
              <img src={logo} alt="logo" height="40" />
            </li>
          </ul>
        </div>
      </nav>
      <main className="practice-test">
        <section className="row answer-section">
          <div className="col-12">
            <h5 className="mb-0 text-center">
              <div className="pb-2">Title : {stateData.subChapterName}</div>
              <div>
                Subject : {stateData.subject} | Chapter :{" "}
                {stateData.chapterName}
              </div>
            </h5>
          </div>
        </section>
        <section>
          <div className="row">
            <div className="col-12 questions">
              <div className="ml-sm-1 pl-sm-1 pt-2 questionBox">
                <div className="py-2 ">
                  <div className="mb-3">
                    <span className="q-no-bg">{questionNo} </span>
                  </div>
                  <div className={"test mb-4"}>
                    <MathJaxContext>
                      <MathJax className="formula" inline dynamic>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: questions.question,
                          }}
                        />
                      </MathJax>
                    </MathJaxContext>
                  </div>
                </div>
                <div
                  className="ml-md-3 ml-sm-3 pl-md-5 pt-sm-0 pt-3 q-opt-wrap option"
                  id="options"
                >
                  <div className="card mb-3">
                    <div id="answer-1" className="card-body">
                      <div id="spanAnswer-1" className="spanAnswer d-none">
                        <span>Your Answer</span>
                      </div>
                      <label className="options">
                        <MathJaxContext>
                          <MathJax className="formula" inline dynamic>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: questions.opt_1,
                              }}
                            />
                          </MathJax>
                        </MathJaxContext>
                        <input
                          checked={formData.answer === "1"}
                          value={1}
                          onChange={handleChange}
                          type="radio"
                          name="answer"
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                  <div className="card mb-3">
                    <div id="answer-2" className="card-body">
                      <div id="spanAnswer-2" className="spanAnswer d-none">
                        <span>Your Answer</span>
                      </div>
                      <label className="options ">
                        <MathJaxContext>
                          <MathJax className="formula" inline dynamic>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: questions.opt_2,
                              }}
                            />
                          </MathJax>
                        </MathJaxContext>
                        <input
                          checked={formData.answer === "2"}
                          value={2}
                          onChange={handleChange}
                          type="radio"
                          name="answer"
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                  <div className="card mb-3">
                    <div id="answer-3" className="card-body">
                      <div id="spanAnswer-3" className="spanAnswer d-none">
                        <span>Your Answer</span>
                      </div>
                      <label className="options">
                        <MathJaxContext>
                          <MathJax className="formula" inline dynamic>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: questions.opt_3,
                              }}
                            />
                          </MathJax>
                        </MathJaxContext>
                        <input
                          checked={formData.answer === "3"}
                          value={3}
                          onChange={handleChange}
                          type="radio"
                          name="answer"
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                  <div className="card mb-3" id="test">
                    <div id="answer-4" className="card-body">
                      <div id="spanAnswer-4" className="spanAnswer d-none">
                        <span>Your Answer</span>
                      </div>
                      <label className="options ">
                        <MathJaxContext>
                          <MathJax className="formula" inline dynamic>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: questions.opt_4,
                              }}
                            />
                          </MathJax>
                        </MathJaxContext>
                        <input
                          checked={formData.answer === "4"}
                          value={4}
                          onChange={handleChange}
                          type="radio"
                          name="answer"
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="my-3" id="answerBox">
                  <div className="card-body">
                    {
                      <div
                        className="answerBox"
                        dangerouslySetInnerHTML={{ __html: displayAnswer }}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="form-inline subjects-submit-button checkansButtonDiv">
          <button
            id="myButton"
            className="d-none btn btn-outline-success  checkansButton"
            onClick={displayCorrectAnswer}
            type="submit"
          >
            Check
          </button>
        </div>
        <div className="loader">
          <div className="loading"></div>
        </div>
        <ReactPaginate
          pageCount={parseInt(pageCount)}
          previousLabel={"PREVIOUS"}
          nextLabel={"NEXT"}
          breakLabel={"..."}
          marginPagesDisplayed={null}
          pageRangeDisplayed={-1}
          containerClassName={"pagination justify-content-center"}
          pageClassName={"btn btn-outline-success my-2 my-sm-0"}
          pageLinkClassName={"page-link"}
          previousClassName={"previous-page"}
          previousLinkClassName={"btn btn-outline-success my-2 my-sm-0"}
          nextClassName={"next-page"}
          nextLinkClassName={"btn btn-outline-success my-2 my-sm-0"}
          breakClassName={"btn-outline-success"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
          onPageChange={getQuestions}
          forcePage={questionNo - 1}
        />
      </main>
    </div>
  );
}

export default PracticeTest;
