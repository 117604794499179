import React from "react";
import { useLocation, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Nav from "./Nav";

function PrivacyPolicy() {
  const locations = useLocation();
  const stateData = locations.state;

  const emailState = stateData.email;
  var lastKey = Object.keys(emailState).sort().reverse()[0];
  var lastValue = emailState[lastKey];

  const email =
    typeof emailState != "object"
      ? emailState
      : lastValue.from
      ? lastValue.from
      : lastValue;
  return (
    <div className="fixed-sn cyan-skin">
      {<Nav email={email} />}
      <section
        className="content-wrapper"
        style={{ backgroundColor: "#f4f5f7" }}
      >
        <div className="container py-5 mt-5">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <h1>Privacy Policy</h1>
            <p>
              This privacy policy outlines the details of the information we
              collect and its usage. Please note that this privacy policy is
              applicable to websites falling under the primary holder{" "}
              <a
                style={{ color: "rgb(26, 115, 232)" }}
                href="https://testportal.sindhyaacademy.com"
                target="_blank"
                rel="noreferrer"
              >
                SINDHYA ACADEMY
              </a>{" "}
              and the Sindhya Exam Mobile app.
            </p>

            <h2>Routine Information Collection</h2>
            <p>
              Our web servers routinely gather basic information such as IP
              addresses, browser details, timestamps, and referring pages for
              administrative and maintenance purposes. This data helps us
              understand which pages are useful to visitors. Additionally, our
              App may collect personal identification information, including
              names, email addresses, mailing addresses, phone numbers when
              users register or engage in various activities on our App. Users
              can choose to remain anonymous, and providing personal
              identification information is voluntary, with the option to
              decline, though it may limit certain App-related activities.
            </p>

            <h2>Cookies and Web Beacons</h2>
            <p>
              Cookies are utilized on this site to store visitor preferences and
              history for a more personalized experience. Advertising partners
              and third parties may also employ cookies, scripts, and web
              beacons for tracking visitors and displaying relevant
              advertisements. This tracking is managed by the third parties and
              is subject to their respective privacy policies.
            </p>

            <h2>Data Usage</h2>
            <p>
              Collected personal identification information may be used for:
            </p>
            <ul>
              <li>Personalizing user experience</li>
              <li>Improving our App</li>
              <li>Sending periodic emails</li>
              <li>
                Running promotions, contests, surveys, or other Site features
              </li>
              <li>Responding to customer service requests</li>
            </ul>

            <h2>Security</h2>
            <p>
              We employ secure data collection, storage, and processing
              practices to protect against unauthorized access, alteration,
              disclosure, or destruction of personal information, including
              usernames, passwords, and transaction data.
            </p>

            <h2>Controlling Your Privacy</h2>
            <p>
              Users can manage their privacy preferences by adjusting browser
              settings to disable cookies. While disabling cookies for all sites
              is not recommended, users can selectively enable or disable
              cookies on a per-site basis. Refer to your browser documentation
              for instructions on managing cookies and tracking mechanisms.
            </p>

            <h2>Cancellation of Account</h2>
            <p>
              Users can request the deletion of personal information by
              contacting us at{" "}
              <a href="mailto:sindhyaacademy@gmail.com">
                sindhyaacademy@gmail.com
              </a>
              . We will promptly respond and delete the information from our
              records, unless legal requirements necessitate retention.
            </p>

            <h2>Changes to this Privacy Policy</h2>
            <p>
              Our App reserves the right to update this privacy policy, with
              notifications posted on the main page, revision of the updated
              date, and email alerts. Users are encouraged to check this page
              frequently for updates, acknowledging their responsibility to
              review the policy periodically.
            </p>

            <p>
              For any questions or concerns about our privacy policy, please
              contact us at{" "}
              <a href="mailto:sindhyaacademy@gmail.com">
                sindhyaacademy@gmail.com
              </a>
              .
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PrivacyPolicy;
