import business from './../../images/pricing/pricing-business.png'
import free from './../../images/pricing/pricing-free.png'
import starter from './../../images/pricing/pricing-starter.png'
import ultimate from './../../images/pricing/pricing-ultimate.png'
export const PriceImg ={
    business : business,
    free : free,
    starter : starter,
    ultimate : ultimate
}
