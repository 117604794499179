import React, {useEffect, useState, useRef} from "react";
import {useLocation, Link} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Nav from "./Nav";
import Breadcrumbs from "./Breadcrumbs";
import axios from "axios";
import '../css/Dashboard.css';
import $ from 'jquery';
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.css';
import 'font-awesome/css/font-awesome.min.css';

function StudentDashboard() {

    const ref = useRef();

    useEffect(() => {
        $(function () {
            $('.navbar-sidenav [data-toggle="tooltip"]').tooltip({
                template: '<div className="tooltip navbar-sidenav-tooltip" role="tooltip" style="pointer-events: none;"><div className="arrow"></div><div className="tooltip-inner"></div></div>'
            })
            // // Toggle the side navigation
            $("#sidenavToggler").click(function(e) {
                e.preventDefault();
                $(".body").toggleClass("sidenav-toggled");
                $(".navbar-sidenav .nav-link-collapse").addClass("collapsed");
                $(".navbar-sidenav .sidenav-second-level, .navbar-sidenav .sidenav-third-level").removeClass("show");
            });
            // // Force the toggled class to be removed when a collapsible nav link is clicked
            $(".navbar-sidenav .nav-link-collapse").click(function(e) {
                e.preventDefault();
                $(".body").removeClass("sidenav-toggled");
            });
            // // Prevent the content wrapper from scrolling when the fixed side navigation hovered over
            $('.body.fixed-nav .navbar-sidenav, .body.fixed-nav .sidenav-toggler, .body.fixed-nav .navbar-collapse').on('mousewheel DOMMouseScroll', function(e) {
                var e0 = e.originalEvent,
                    delta = e0.wheelDelta || -e0.detail;
                this.scrollTop += (delta < 0 ? 1 : -1) * 30;
                e.preventDefault();
            });
            // // Scroll to top button appear
            $(document).scroll(function() {
                var scrollDistance = $(this).scrollTop();
                if (scrollDistance > 100) {
                    $('.scroll-to-top').fadeIn();
                } else {
                    $('.scroll-to-top').fadeOut();
                }
            });
            // Configure tooltips globally
            $('[data-toggle="tooltip"]').tooltip()
            // Smooth scrolling using jQuery easing
            $(document).on('click', 'a.scroll-to-top', function(event) {
                var $anchor = $(this);
                $('html, .body').stop().animate({
                    scrollTop: ($($anchor.attr('href')).offset().top)
                }, 1000, 'easeInOutExpo');
                event.preventDefault();
            });
        });
    }, [ref.current]);

    const locations = useLocation()
    const stateData = locations.state
    // console.log(stateData)

    const emailState = stateData.email
    var lastKey = Object.keys(emailState).sort().reverse()[0];
    var lastValue = emailState[lastKey];

    const email = (typeof emailState != 'object') ? emailState
        : lastValue.from ? lastValue.from
            : lastValue;

    const [subjects, setSubjects] = useState([])
    const getSubjectsData = async () => {
        const url = `/subjectsData`;
        axios.get(url, {
            params: {
                email: email,
            },
        }).then(res => {
            const data = res.data;
            setSubjects(data)
        }).catch(err => {
            console.log(err);
        })
    };
    useEffect(() => {
        getSubjectsData();
    }, []);

    function subjectList() {
        return (
            <>
                {
                    subjects.map((subjectData) => {
                        const keyValue = subjectData.key
                        const class_name = keyValue === 1 ? 'bg-bio' : keyValue === 2 ? 'bg-che' : 'bg-phy'
                        return (
                            <div key={keyValue} className="col-xl-3 col-sm-6 mb-3">
                                <div style={subjectData.color ? {background: subjectData.color } : {background: '#014751' }} className={`card text-white hot-card`}>
                                    <div className="card-body" title={subjectData.name}>
                                        <div className="card-body-icon">
                                            <div dangerouslySetInnerHTML={{__html: subjectData.icon}} />
                                        </div>
                                        <h5>{subjectData.name}</h5>
                                    </div>
                                    <Link to={"/subjects?sid=" + subjectData.key} state={{ email: email, studentId: stateData.studentId, studentData: stateData.studentData }} className="card-footer text-white clearfix small z-1" >
                                        <span className="float-left">Start Learning</span>
                                        <span className="float-right">
                                            <i className="fa fa-angle-right"></i>
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        )
                    })
                }
            </>
        );
    }


    return(
        <div className="dashboard-nav">
            {<Nav subjects={subjects} email={email}/>}
            <div className="content-wrapper">
                <div className="container">
                    {<Breadcrumbs subjects={subjects} email={email}/>}
                    <div className="row">
                        {subjectList()}
                        <div className="col-xl-3 col-sm-6 mb-3">
                            <div className="card text-white bg-test hot-card">
                                <div className="card-body">
                                    <div className="card-body-icon">
                                        <i className="fa fa-hourglass-2"></i>
                                    </div>
                                    <h5>Test Series</h5>
                                </div>
                                <Link to={"/mockTest"} state={{ email: email, studentId: stateData.studentId, studentData: stateData.studentData}} className="card-footer text-white clearfix small z-1" >
                                    <span className="float-left">Start the Test</span>
                                    <span className="float-right">
                                        <i className="fa fa-angle-right"></i>
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StudentDashboard;

