import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./../css/StudentDashboard.css";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import Nav from "../login/Nav";
import Breadcrumbs from "../login/Breadcrumbs";

function Standard() {
  const location = useLocation();
  const stateData = location.state;
  // console.log(stateData)
  const [chapters, setChapters] = useState([]);

  const getTestSubjects = async () => {
    const url = `/standard`;
    axios
      .get(url, {
        params: {
          examFolderId: stateData.examFolderId,
        },
      })
      .then((res) => {
        const result = res.data;
        // console.log(result)
        setChapters(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getTestSubjects();
  }, []);

  function subChapterList() {
    if (chapters.length) {
      return (
        <div className="chapter-cover">
          {chapters.map((chaptersData, index) => {
            const examFolderId = stateData.examFolderId;
            const serialNo = index + 1;
            const class_name =
              parseInt(examFolderId) === 1
                ? "bio"
                : parseInt(examFolderId) === 2
                ? "che"
                : parseInt(examFolderId) === 3
                ? "phy"
                : "test";
            var cno = serialNo < 10 ? `${"0" + serialNo}` : serialNo;
            return (
              <div key={index} className="chapter">
                <Link
                  to={"/mockExamList"}
                  state={{
                    class: chaptersData.sub_exam_folder_name,
                    test: stateData.test,
                    subjectName: stateData.subjectName,
                    subExamFolderId: chaptersData.sub_exam_folder_id,
                    examFolderId: chaptersData.exam_folder_id,
                    email: stateData.email,
                    studentId: stateData.studentId,
                    studentData: stateData.studentData,
                  }}
                  className={`chapter-item ${class_name}`}
                >
                  <span
                    style={{ color: chapters.color }}
                    className="chapter-no"
                  >
                    {cno}
                  </span>
                  <div className="chapter-info">
                    <h5 className="mr-5">
                      {chaptersData.sub_exam_folder_name}
                    </h5>
                    <span className="goto-arrow text-secondary">
                      <i className="fa fa-angle-right"></i>
                    </span>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      );
    }
  }

  return (
    <div className="fixed-sn cyan-skin biology">
      {
        <Nav
          test={stateData.test}
          subjectName={stateData.subjectName}
          subjectId={stateData.examFolderId}
          email={stateData.email}
        />
      }

      <div className="content-wrapper">
        <div className="container">
          {
            <Breadcrumbs
              test={stateData.test}
              subjectName={stateData.subjectName}
              subjectId={stateData.examFolderId}
              email={stateData.email}
            />
          }
          <main>
            <div>
              <section className="mb-4">
                <div className="subj-header py-3">
                  <div
                    style={{ color: chapters.color }}
                    className="subj-nav-icon"
                    dangerouslySetInnerHTML={{ __html: chapters.icon }}
                  />
                  <h2 className="mb-0 sub-name">
                    <strong>{stateData.subjectName}</strong>
                  </h2>
                </div>
              </section>
              <section>
                <div className="row">{subChapterList()}</div>
              </section>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}

export default Standard;
