import React, {useEffect, useState} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './../css/StudentDashboard.css';
import {Link, useLocation} from 'react-router-dom'
import axios from "axios";
import Nav from "../login/Nav";
import Breadcrumbs from "../login/Breadcrumbs";

function MockTest() {
    const location = useLocation()
    const stateData = location.state
    // console.log(stateData)
    const headerName = 'Test'
    const [chapters, setChapters] = useState([])

    const getTestSubjects = async () => {
        const url = `/mockTest`;
        axios.get(url).then(res => {
            const result = res.data;
            setChapters(result)
        }).catch(err => {
            console.log(err);
        })
    };
    useEffect(() => {
        getTestSubjects();
    }, []);

    function subChapterList() {
        if(chapters.length) {
            return (
                <div className="row">
                    {
                        chapters.map((chaptersData) => {
                            const keyValue = chaptersData.exam_folder_id
                            const class_name = keyValue === 1 ? 'bg-bio' :
                                keyValue === 2 ? 'bg-che' :
                                    keyValue === 3 ? 'bg-phy' :
                                    keyValue === 4 ? 'bg-test' : 'bg-prev'
                            return (
                                <div key={keyValue} className="col-xl-3 col-sm-6 mb-3">
                                    <div className={`card text-white ${class_name} hot-card`}>
                                        <div className="card-body">
                                            <div className="card-body-icon">
                                                <i className="fa fa-flask"></i>
                                            </div>
                                            <h5>{chaptersData.folder_name}</h5>
                                        </div>
                                        <Link to={"/standard"} state={{
                                            test: headerName,
                                            subjectName: chaptersData.folder_name,
                                            examFolderId: chaptersData.exam_folder_id,
                                            email: stateData.email,
                                            studentId: stateData.studentId,
                                            studentData: stateData.studentData
                                        }} className="card-footer text-white clearfix small z-1">
                                            <span className="float-left">Start Testing Your Level</span>
                                            <span className="float-right">
                                            <i className="fa fa-angle-right"></i>
                                        </span>
                                        </Link>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            );
        }
    }

    return(
        <div className="fixed-sn cyan-skin biology">
            {<Nav email={stateData.email} test={headerName}/>}
            <div className="content-wrapper">
                <div className="container">
                    {<Breadcrumbs email={stateData.email} test={headerName}/>}
                    <main >
                        <div className="pt-4">
                            <section className="mb-4">
                                <div className="card-header  py-3">
                                    <h3 className="mb-0">
                                        <strong>Mock Test</strong>
                                    </h3>
                                </div>
                            </section>
                            <section>
                                {subChapterList()}
                            </section>
                        </div>
                    </main>
                </div>
            </div>
        </div>
    )
}

export default MockTest;

