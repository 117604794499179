import React from "react";
import "./Popup.css";
import { Link } from "react-router-dom";
import { PriceImg } from "./PriceImg";
export const Popup = ({ email, closePopup }) => {
  return (
    <div className="popup-container">
      <div className="popup-body">
        {" "}
        <a
          type="button"
          className="close"
          data-dismiss="modal"
          onClick={closePopup}
        >
          <span aria-hidden="true" className="text-danger">
            &times;
          </span>
        </a>
        <section id="pricing" className="pricing">
          <div className="container aos-init aos-animate" data-aos="fade-up">
            <header className="section-header">
              <p>NEET Online Test Series Pricing</p>
            </header>
            <div className="row gy-3 aos-init aos-animate" data-aos="fade-left">
              <div
                className="col-lg-3 col-md-6 aos-init aos-animate"
                data-aos="zoom-in"
                data-aos-delay="100"
              >
                <div className="box">
                  <h3 style={{ color: "#07d5c0" }}>Free Plan</h3>
                  <div className="price">
                    <sup>Rs.</sup>0
                  </div>
                  <img src={PriceImg.free} className="img-fluid" alt="" />
                  <ul>
                    <li>03 Chapter-wise Practise Test(1,000 MCQ)</li>
                    <li>1 Previous Year Paper</li>
                    <li className="na">Chapter-wise Test</li>
                    <li className="na">Part-syllabus Mock Test</li>
                    <li className="na">Subject level Mock Test</li>
                    <li className="na">Unit-wise Test</li>
                    <li className="na">Full-syllabus Test</li>
                    <li className="na">Full-syllabus Mock Test</li>
                  </ul>
                  <Link
                    className="nav-link"
                    to={"/bank-details"}
                    state={{
                      email: email,
                    }}
                  >
                    <span className="btn-buy">Buy Now</span>
                  </Link>
                </div>
              </div>

              <div
                className="col-lg-3 col-md-6 aos-init aos-animate"
                data-aos="zoom-in"
                data-aos-delay="200"
              >
                <div className="box">
                  <h3 style={{ color: "#65c600" }}>For 11th</h3>
                  <div className="price">
                    <sup>Rs.</sup>6500
                  </div>
                  <img src={PriceImg.starter} className="img-fluid" alt="" />
                  <ul>
                    <li>625 Chapter-wise Practise Test(25,000 MCQ)</li>
                    <li>50 Chapter-wise Test</li>
                    <li>10 Unit-wise Test</li>
                    <li>40 Part-syllabus Test</li>
                    <li>20 Subject level Mock Test</li>
                    <li className="na">10 Full-syllabus Test</li>
                    <li className="na">15 Previous Year Paper</li>
                    <li>Valid till NEET 2024 Exam Date</li>
                  </ul>
                  <Link
                    className="nav-link"
                    to={"/bank-details"}
                    state={{
                      email: email,
                    }}
                  >
                    <span className="btn-buy">Buy Now</span>
                  </Link>
                </div>
              </div>

              <div
                className="col-lg-3 col-md-6 aos-init aos-animate"
                data-aos="zoom-in"
                data-aos-delay="300"
              >
                <div className="box">
                  <div className="repeater-cls">
                    <h3 style={{ color: "#ff901c" }}>For 11th and 12th</h3>
                    <span>(Repeaters)</span>
                  </div>
                  <div className="price">
                    <sup>Rs.</sup>9500
                  </div>
                  <img src={PriceImg.business} className="img-fluid" alt="" />
                  <ul>
                    <li>1225 Chapter-wise Practise Test (45,000 MCQ)</li>
                    <li>88 Chapter-wise Test</li>
                    <li>20 Unit-wise Test</li>
                    <li>60 Part-syllabus Test</li>
                    <li>30 Subject level Test</li>
                    <li>10 Full-syllabus Test</li>
                    <li>15 Previous Year Paper</li>
                    <li>Valid till NEET 2025 Exam Date</li>
                  </ul>
                  <Link
                    className="nav-link"
                    to={"/bank-details"}
                    state={{
                      email: email,
                    }}
                  >
                    <span className="btn-buy">Buy Now</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
