import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./../css/StudentDashboard.css";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import Nav from "../login/Nav";
import Breadcrumbs from "../login/Breadcrumbs";
import { Popup } from "../popup/Popup";

function MockExamList() {
  const location = useLocation();
  const [subChapters, setSubChapters] = useState([]);
  const [stateData, setStateData] = useState({});
  const [open, setOpen] = useState(false);
  const getSubChaptersList = async () => {
    const url = `/mockExamList`;
    axios
      .get(url, {
        params: {
          subExamFolderId: location.state.subExamFolderId,
        },
      })
      .then((res) => {
        const result = res.data[0];
        setSubChapters(result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getSubChaptersList();
    setStateData(location.state);
  }, []);

  function subChapterExamList() {
    if (subChapters.length) {
      return (
        <div className="chapter-cover">
          {subChapters.map((subChaptersData) => {
            const student_id = subChaptersData.newData.student_id;
            const examAttended = student_id.split(",");
            const examAttendedStatus = examAttended.includes(
              location.state.studentId?.toString()
            ); // it says false means student not attempted the exam.
            const primeUser = stateData?.studentData?.prime_user
              ? true
              : subChaptersData.newData.status === 1;
            const verifyStudent = !examAttendedStatus ? primeUser : false;
            const sub_chapter_id = subChaptersData.newData.exam_id;
            const sub_chapter_name = subChaptersData.newData.exam_name;

            return (
              <div
                onClick={() => setOpen(true)}
                key={sub_chapter_id}
                className="sub-chapter"
              >
                <Link
                  to={verifyStudent ? `/finalTestQuestions` : `#`}
                  state={{
                    countOfQuestions: subChaptersData.newData.totalQuestions,
                    examId: subChaptersData.newData.exam_id,
                    examDuration: subChaptersData.newData.exam_duration,
                    examName: subChaptersData.newData.exam_name,
                    examNmark: subChaptersData.newData.exam_nmark,
                    examPmark: subChaptersData.newData.exam_pmark,
                    email: stateData.email,
                    studentId: stateData.studentId,
                    studentData: stateData.studentData,
                  }}
                  className="sub-chapter-item"
                >
                  {/*<span className="chapter-no">{cno}</span>*/}
                  <div className="chapter-info">
                    <h5 className="mr-5 d-inline-block">{sub_chapter_name}</h5>
                    <div className="text-secondary">
                      <small className="me-3">
                        Questions : {subChaptersData.newData.totalQuestions}
                      </small>
                      <small>
                        Duration : {subChaptersData.newData.exam_duration}
                      </small>
                    </div>
                    <span>
                      {!verifyStudent && (
                        <i
                          className="fa fa-lock lock_Style_Test exam"
                          aria-hidden="true"
                        ></i>
                      )}
                    </span>
                    <span className="goto-arrow_Test text-secondary">
                      <i className="fa fa-angle-right"></i>
                    </span>
                  </div>
                </Link>
              </div>
            );
          })}
          {open ? <Popup email={stateData.email} closePopup={() => setOpen(false)} /> : null}
        </div>
      );
    }
  }

  return (
    <div className="fixed-sn cyan-skin biology">
      {
        <Nav
          className={stateData.class}
          examFolderId={stateData.examFolderId}
          test={stateData.test}
          subjectName={stateData.subjectName}
          exam={stateData.exam}
          studentId={stateData.studentId}
          studentData={stateData.studentData}
        />
      }
      <div className="content-wrapper">
        <div className="container">
          {
            <Breadcrumbs
              className={stateData.class}
              examFolderId={stateData.examFolderId}
              test={stateData.test}
              subjectName={stateData.subjectName}
              exam={stateData.exam}
              mockExam={"Mock Exam List"}
            />
          }
          <main>
            <div>
              <section className="mb-4">
                <div className="card-header py-3">
                  <h5 className="mb-0">
                    <strong>{stateData.class}</strong>
                  </h5>
                </div>
              </section>
              <section>
                <div className="row">{subChapterExamList()}</div>
              </section>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}

export default MockExamList;
