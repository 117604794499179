function PreventGoingBack() {
    function preventGoingPageBack() {
        var backButtonPrevented = false;
        window.history.pushState(null, document.title, window.location.href);
        function popStateListener(event) {
            if (backButtonPrevented === false){
                window.history.pushState(null, document.title, window.location.href);
                alert('If you leave before saving, your changes will be lost.')
                backButtonPrevented = true;
                // document.getElementById("end-practice").click();
            }
            window.removeEventListener('popstate', popStateListener);
        }
        window.addEventListener('popstate', popStateListener)
        // window.onbeforeunload = function() { alert( "Your work will be lost.") }
    }
    return(
        preventGoingPageBack()
    )
}

export default PreventGoingBack;

