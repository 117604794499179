import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Nav from "./Nav";
import axios from "axios";

function MyProfile() {
  const locations = useLocation();
  const stateData = locations.state;

  const emailState = stateData.email;
  var lastKey = Object.keys(emailState).sort().reverse()[0];
  var lastValue = emailState[lastKey];

  const email =
    typeof emailState != "object"
      ? emailState
      : lastValue.from
      ? lastValue.from
      : lastValue;

  const [studentData, setStudentData] = useState([]);
  const [formData, setFormData] = React.useState({});
  const [updateData, setUpdateData] = React.useState({
    student_password: "",
  });
  const getStudentData = async () => {
    const url = `/studentDetails`;
    axios
      .get(url, {
        params: {
          email: email,
        },
      })
      .then((res) => {
        const data = res.data;
        setFormData({
          student_name: data.student_name,
          student_phone: data.student_phone,
          student_school: data.student_school,
          class: data.class,
          student_password: data.student_password,
        });
        setStudentData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getStudentData();
  }, []);

  function handleChange(event) {
    const { name, type, value, checked } = event.target;
    setUpdateData((prevState) => {
      return {
        ...prevState,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  }

  function handleClick(event) {
    if (updateData.student_password !== updateData.student_same_password) {
      alert("Password doesn't match");
      return false;
    }
    event.preventDefault();
    const url = `/updateStudentProfile`;
    axios
      .put(url, {
        params: {
          email: email,
          student_password: updateData.student_password,
        },
      })
      .then((res) => {
        alert("Updated Successfully");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function studentDatas() {
    return (
      <div className="row pt-1">
        <div className="d-flex justify-content-between">
          <h6>Name</h6>
          <p className="text-muted">{formData.student_name}</p>
        </div>
        <div className="d-flex justify-content-between">
          <h6>Email</h6>
          <p className="text-muted">{email}</p>
        </div>
        <div className="d-flex justify-content-between">
          <h6>Phone</h6>
          <p className="text-muted">{formData.student_phone}</p>
        </div>
        <div className="d-flex justify-content-between">
          <h6>School</h6>
          <p className="text-muted">{formData.student_school}</p>
        </div>
        <div className="d-flex justify-content-between">
          <h6>Class</h6>
          <p className="text-muted">{formData.class}</p>
        </div>
        <hr className="mt-0 mb-4" />
        <h6>Update Password</h6>
        <div className="row">
          <div className="col-md-6 mb-4">
            <div className="form-outline">
              <input
                required
                name="student_password"
                value={updateData.student_password}
                onChange={handleChange}
                type="password"
                placeholder={"Password"}
                pattern="(?=.*\d).{8,20}"
                title="Must contain at least 8 or more characters"
                className="form-control form-control-lg"
              />
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="form-outline">
              <input
                required
                name="student_same_password"
                value={updateData.student_same_password}
                onChange={handleChange}
                type="password"
                placeholder={"Re-type password"}
                className="form-control form-control-lg"
              />
            </div>
          </div>
        </div>
        <div className="update-btn col-8 mb-3">
          <button
            onClick={handleClick}
            type="button"
            className="btn btn-success"
          >
            Update
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed-sn cyan-skin">
      {<Nav email={email} />}
      <section
        className="content-wrapper"
        style={{ backgroundColor: "#f4f5f7" }}
      >
        <div className="container py-5 mt-5">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-md-8" style={{ textAlign: "justify" }}>
              <div className="card-body p-4">
                <h6>My Profile</h6>
                <hr className="mt-0 mb-4" />
                {studentDatas()}
              </div>
            </div>
          </div>
        </div>
        <footer id="footer" class="footer">
          <div class="container privacy-policy">
            <div>
              <Link
                className="nav-link"
                to={"/privacy-policy"}
                state={{
                  email: email,
                }}
              >
                <i className="fa fa-expeditedssl"></i>
                <span className="nav-link-text px-2 link-primary">
                  Privacy policy
                </span>
              </Link>
            </div>
            <div class="copyright">
              © Copyright{" "}
              <strong>
                <span>Sindhya Exam Portal</span>
              </strong>
              . All Rights Reserved
            </div>
            <div class="credits">
              Designed by{" "}
              <a href="https://sindhyasoftware.com/">Sindhya Software</a>
            </div>
          </div>
        </footer>
      </section>
    </div>
  );
}

export default MyProfile;
