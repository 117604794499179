import React from "react";
import { useLocation, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Nav from "./Nav";

function BankDetails() {
  const locations = useLocation();
  const stateData = locations.state;

  const emailState = stateData.email;
  var lastKey = Object.keys(emailState).sort().reverse()[0];
  var lastValue = emailState[lastKey];

  const email =
    typeof emailState != "object"
      ? emailState
      : lastValue.from
      ? lastValue.from
      : lastValue;
  return (
    <div className="fixed-sn cyan-skin">
      {<Nav email={email} />}
      <section
        className="content-wrapper"
        style={{ backgroundColor: "#f4f5f7" }}
      >
        <div className="container py-5 mt-5">
          <div class="row justify-content-center">
            <div class="col-md-6">
              <div class="card">
                <div class="card-body">
                  <div class="h2 font-weight-bold mb-4 text-center">
                    Bank Details
                  </div>
                  <div>
                    <table class="table">
                      <tbody>
                        <tr>
                          <td class="font-weight-bold text-left">
                            <strong>Bank Account Name:</strong>
                          </td>
                          <td class="text-left">SINDHYA SOFTWARE</td>
                        </tr>
                        <tr>
                          <td class="font-weight-bold text-left">
                            <strong>Bank Account Number:</strong>
                          </td>
                          <td class="text-left">131402000001044</td>
                        </tr>
                        <tr>
                          <td class="font-weight-bold text-left">
                            <strong>IFSC Code:</strong>
                          </td>
                          <td class="text-left">IOBA0001314</td>
                        </tr>
                        <tr>
                          <td class="font-weight-bold text-left">
                            <strong>Bank:</strong>
                          </td>
                          <td class="text-left">IOB Bank</td>
                        </tr>
                        <tr>
                          <td class="font-weight-bold text-left">
                            <strong>Branch:</strong>
                          </td>
                          <td class="text-left">DINDIGUL</td>
                        </tr>
                        <tr>
                          <td class="font-weight-bold text-left">
                            <strong>Contact Details:</strong>
                          </td>
                          <td class="text-left"><p>+91 9788845111<br />+91 9788842101</p></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default BankDetails;
