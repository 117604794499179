import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./../css/StudentDashboard.css";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import ReactPaginate from "react-paginate";
import PreventGoingBack from "./PreventGoingBack";
import CountdownTimer from "../../hooks/CountdownTimer";
import "../../hooks/Timer.css";
import { MathJax, MathJaxContext } from "better-react-mathjax";
import "./style.css";
import logo from "../../images/sindhya_logo.svg";
import $ from "jquery";

function FinalTest() {
  const location = useLocation();
  const stateData = location.state;
  // console.log(stateData)
  const examDuration = stateData.examDuration;
  const examName = stateData.examName;
  const examNmark = stateData.examNmark;
  const examPmark = stateData.examPmark;
  const examId = stateData.examId;
  const [questions, setQuestions] = useState([]);
  const [questionNo, setQuestionNo] = useState(1);
  const [questionStartNo, setQuestionStartNo] = useState();
  const [questionEndNo, setQuestionEndNo] = useState();
  const [pageCount, setPageCount] = useState([]);
  const [ansSelected, setAnsSelected] = useState([]);
  const [studentId, setStudentId] = useState();
  const [finalData, setFinalData] = React.useState({
    selectedAnswer: "",
    answer: "",
  });
  const TWO_HRS_IN_MS = 1 * 120 * 60 * 1000;
  const NOW_IN_MS = new Date().getTime();

  const [timer, setTimer] = React.useState(NOW_IN_MS + TWO_HRS_IN_MS);
  let finalResult = [];
  const countOfQuestns = stateData.countOfQuestions;
  const pageLimit = 1;
  const getQuestions = async (data) => {
    let currentPage = data ? data.selected + 1 : 1;
    const scrollElement = document.querySelector(".practice-test .questionBox");
    scrollElement.scrollTop -= 500;
    if (typeof data?.target !== "undefined") {
      currentPage = parseInt(data.target.getAttribute("value"));
    }
    if (!isNaN(currentPage)) {
      setQuestionNo(currentPage);
      const url = `/finalTestQuestions?_page=${currentPage}&_limit=${pageLimit}`;
      axios
        .get(url, {
          params: {
            examId: examId,
            countOfQuestions: countOfQuestns,
          },
        })
        .then((res) => {
          const result = res.data[0];
          setQuestions(result.data[0]);
          setQuestionStartNo(parseInt(result.questionStart));
          setQuestionEndNo(parseInt(result.questionTo));
          setPageCount(Math.ceil(result.count / pageLimit));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getStudentData = async () => {
    const url = `/studentDetails`;
    axios
      .get(url, {
        params: {
          email: stateData.email,
        },
      })
      .then((res) => {
        const data = res.data;
        setStudentId(data.student_id);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getQuestions();
    getStudentData();
    PreventGoingBack();
  }, []);

  useEffect(() => {
    renderTD();
  }, [pageCount]);

  useEffect(() => {
    ansSelected.map((selected) => {
      var element = document.getElementById(selected);
      element.classList.add("bg-success");
      element.classList.remove("bg-danger");
    });
  }, [ansSelected]);

  function handleChange(event) {
    if (!ansSelected.includes(`question-${questionNo}`)) {
      setAnsSelected([...ansSelected, `question-${questionNo}`]);
    }
    const { name, type, value, checked } = event.target;
    setFinalData((prevState) => {
      return {
        ...prevState,
        ["answer" + questions.question_id]: questions.answer,
        ["selectedAnswer" + questions.question_id]: value,
      };
    });
  }
  function FinalSubmit() {
    let correctAnswer = 0;
    let correctAnswerArray = [];
    let wrongAnswer = 0;
    let wrongAnswerArray = [];
    let unAttemptedAnswer = 0;
    let unAttemptedAnswerArray = [];
    for (let i = questionStartNo; i <= questionEndNo; i++) {
      const success = finalData["answer" + i];
      const attempt = finalData["selectedAnswer" + i];
      if (!finalData["answer" + i]) {
        unAttemptedAnswer++;
        unAttemptedAnswerArray = unAttemptedAnswer;
      } else if (success === attempt) {
        correctAnswer++;
        correctAnswerArray = correctAnswer;
      } else {
        wrongAnswer++;
        wrongAnswerArray = wrongAnswer;
      }
    }
    let positiveMark =
      correctAnswerArray > 0 ? correctAnswerArray * examPmark : 0;
    let negativeMark = wrongAnswerArray > 0 ? wrongAnswerArray * examNmark : 0;
    let unAttemptedAns =
      unAttemptedAnswerArray <= 0 ? 0 : unAttemptedAnswerArray;
    let correctAns = correctAnswerArray <= 0 ? 0 : correctAnswerArray;
    let wrongAns = wrongAnswerArray <= 0 ? 0 : wrongAnswerArray;
    let totalMarks = positiveMark - negativeMark;
    finalResult.push({
      unAttemptedAnswer: unAttemptedAns,
      correctAnswer: correctAns,
      wrongAnswer: wrongAns,
      attempt: finalData,
      total: totalMarks,
      countOfQuestns: countOfQuestns,
      examName: examName,
      examId: examId,
      studentId: studentId,
    });
    const url = `/saveTestAnswers`;
    axios
      .get(url, {
        params: {
          exam_id: examId,
          answers: finalData,
          unattempted: unAttemptedAns,
          correct: correctAns,
          wrong: wrongAns,
          total: totalMarks,
          studentId: studentId,
        },
      })
      .then((res) => {
        const result = res.data[0];
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const renderTD = () => {
    let tableRow, tableColumn, table_text;
    let page_length = parseInt(pageCount);
    let rows = page_length / 10;
    let cell = 1;
    let tr = [];
    // if (page_length >= 10) {
    let tableElement = document.createElement("div");
    tableElement.className = "answered-table";
    for (let i = 0; i < page_length; i++) {
      tableRow = document.createElement("div");
      // for (let j = 0; j < 10; j++) {

      tableRow.setAttribute("class", "question_no");
      tableColumn = document.createElement("span");
      tableColumn.setAttribute("value", cell);
      tableColumn.setAttribute("id", "question-" + cell);
      tableColumn.setAttribute(
        "class",
        "text-white cursor-pointer bg-secondary"
      );
      tableColumn.setAttribute(
        "style",
        "cursor: pointer;border: 1px solid #ddd"
      );
      table_text = document.createTextNode(cell);
      tableColumn.appendChild(table_text);
      tableRow.appendChild(tableColumn);
      tableRow.onclick = getQuestions;
      // if (cell === page_length) {
      //     break;
      // }
      cell++;
      // }
      tableElement.appendChild(tableRow);
      tr.push(tableElement);
    }
    // } else if (page_length < 10) {
    //     let tableElement = document.createElement("div");
    //     tableElement.className = "answered-table"
    //     tableRow = document.createElement("div");
    //     for (let j = 0; j < 10; j++) {
    //         tableColumn = document.createElement("span");
    //         tableColumn.setAttribute('value', cell)
    //         tableColumn.setAttribute('id', 'question-' + cell)
    //         tableColumn.setAttribute('class', 'text-white bg-secondary')
    //         table_text = document.createTextNode(cell);
    //         tableColumn.appendChild(table_text);
    //         tableRow.appendChild(tableColumn);
    //         tableRow.onclick = getQuestions
    //         if (cell === page_length) {
    //             break;
    //         }
    //         cell++;
    //     }
    //     tableElement.appendChild(tableRow);
    //     tr.push(
    //         tableElement
    //     )
    // }
    if (tr.length > 0) {
      document.getElementById("questions-list").append(tr[0]);
    }
  };
  function mobileMenuClick() {
    $("#navbarResponsive").toggleClass("show");
  }
  return (
    <div className="container fixed-sn cyan-skin practice-test-qno final-test-page">
      <nav className="navbar navbar-expand-lg fixed-top" id="mainNav">
        <Link className="navbar-brand">
          <img src={logo} alt="logo" height="40" />
        </Link>
        <button
          onClick={mobileMenuClick}
          className="navbar-toggler navbar-toggler-right"
          type="button"
          data-toggle="collapse"
          data-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul
            className="navbarsize navbar-nav navbar-sidenav "
            id="exampleAccordion"
          >
            <li className="heading">
              <br></br>
              Test Session
            </li>
            <ul className="navbar-nav ">
              <li className="nav-item ">
                <Link
                  to={"/finalTestResult"}
                  state={{
                    email: stateData.email,
                    result: finalResult,
                    studentId: stateData.studentId,
                    studentData: stateData.studentData,
                  }}
                >
                  <button
                    onClick={() => {
                      FinalSubmit();
                    }}
                    className="btn practice-test endButton"
                    type="button"
                  >
                    End Exam
                  </button>
                </Link>
              </li>
            </ul>
            <li
              className="nav-item questionBox"
              data-placement="right"
              title="Test"
            >
              <br></br>
              <div className="pt-4" id="questions-list"></div>
            </li>
            <li className="nav-item">
              <a className="nav-link text-center" id="sidenavToggler">
                <i className="fa fa-fw fa-angle-left"></i>
              </a>
            </li>
          </ul>

          <ul className="navbar-nav logo">
            <li className="nav-item ">
              <img src={logo} alt="logo" height="40" />
            </li>
          </ul>
        </div>
      </nav>
      <main className="practice-test">
        <section className="row answer-section">
          <div className="col-12">
            <h5 className="mb-0 text-center">
              <CountdownTimer targetDate={timer} />
              <div className="pb-2">Exam Name : {examName}</div>
              {/*<div>Negative Mark : {examNmark} | Positive Mark : {examPmark}</div>*/}
            </h5>
          </div>
        </section>
        <section>
          <div className="row">
            <div className="col-12 questions">
              <div className="ml-sm-1 pl-sm-1 pt-2 questionBox">
                <div className="py-2">
                  <div className="mb-3">
                    <span className="q-no-bg">{questionNo} </span>
                  </div>
                  <div className={"test mb-4"}>
                    <MathJaxContext>
                      <MathJax className="formula" inline dynamic>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: questions.question,
                          }}
                        />
                      </MathJax>
                    </MathJaxContext>
                  </div>
                </div>
                <div
                  className="ml-md-3 ml-sm-3 pl-md-5 pt-sm-0 pt-3 q-opt-wrap"
                  id="options"
                >
                  <div className="card mb-3">
                    <div className="card-body">
                      <label className="options">
                        <MathJaxContext>
                          <MathJax className="formula" inline dynamic>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: questions.opt_1,
                              }}
                            />
                          </MathJax>
                        </MathJaxContext>
                        <input
                          checked={
                            finalData[
                              "selectedAnswer" + questions.question_id
                            ] === "1"
                          }
                          value={1}
                          onChange={handleChange}
                          type="radio"
                          name={"selectedAnswer" + questions.question_id}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                  <div className="card mb-3">
                    <div className="card-body">
                      <label className="options ">
                        <MathJaxContext>
                          <MathJax className="formula" inline dynamic>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: questions.opt_2,
                              }}
                            />
                          </MathJax>
                        </MathJaxContext>
                        <input
                          checked={
                            finalData[
                              "selectedAnswer" + questions.question_id
                            ] === "2"
                          }
                          value={2}
                          onChange={handleChange}
                          type="radio"
                          name={"selectedAnswer" + questions.question_id}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                  <div className="card mb-3">
                    <div className="card-body">
                      <label className="options">
                        <MathJaxContext>
                          <MathJax className="formula" inline dynamic>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: questions.opt_3,
                              }}
                            />
                          </MathJax>
                        </MathJaxContext>
                        <input
                          checked={
                            finalData[
                              "selectedAnswer" + questions.question_id
                            ] === "3"
                          }
                          value={3}
                          onChange={handleChange}
                          type="radio"
                          name={"selectedAnswer" + questions.question_id}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                  <div className="card mb-3">
                    <div className="card-body">
                      <label className="options ">
                        <MathJaxContext>
                          <MathJax className="formula" inline dynamic>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: questions.opt_4,
                              }}
                            />
                          </MathJax>
                        </MathJaxContext>
                        <input
                          checked={
                            finalData[
                              "selectedAnswer" + questions.question_id
                            ] === "4"
                          }
                          value={4}
                          onChange={handleChange}
                          type="radio"
                          name={"selectedAnswer" + questions.question_id}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <ReactPaginate
          pageCount={parseInt(pageCount)}
          previousLabel={"PREVIOUS"}
          nextLabel={"NEXT"}
          breakLabel={"..."}
          marginPagesDisplayed={null}
          pageRangeDisplayed={-1}
          containerClassName={"pagination justify-content-center"}
          pageClassName={"btn btn-outline-success my-2 my-sm-0"}
          pageLinkClassName={"page-link"}
          previousClassName={"previous-page"}
          previousLinkClassName={"btn btn-outline-success my-2 my-sm-0"}
          nextClassName={"next-page"}
          nextLinkClassName={"btn btn-outline-success my-2 my-sm-0"}
          breakClassName={"btn-outline-success"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
          onPageChange={getQuestions}
          forcePage={questionNo - 1}
        />
      </main>
    </div>
  );
}

export default FinalTest;
