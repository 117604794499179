import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./../css/StudentDashboard.css";
import student from "./../../images/student.png";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";

import logo from "./../../images/sindhya_logo.svg";
import "../css/Dashboard.css";
import $ from "jquery";
import "jquery-ui-bundle";
import "jquery-ui-bundle/jquery-ui.css";
import "font-awesome/css/font-awesome.min.css";

function Nav(props) {
  // const ref = useRef();

  useEffect(() => {
    $(function () {
      $('.navbar-sidenav [data-toggle="tooltip"]').tooltip({
        template:
          '<div className="tooltip navbar-sidenav-tooltip" role="tooltip" style="pointer-events: none;"><div className="arrow"></div><div className="tooltip-inner"></div></div>',
      });
      // // Toggle the side navigation
      $("#sidenavToggler").click(function (e) {
        e.preventDefault();
        $(".body").toggleClass("sidenav-toggled");
        if ($("div").hasClass("dashboard-nav")) {
          $(".body").toggleClass("sidenav-toggled");
        }
        $(".navbar-sidenav .nav-link-collapse").addClass("collapsed");
        $(
          ".navbar-sidenav .sidenav-second-level, .navbar-sidenav .sidenav-third-level"
        ).removeClass("show");
      });
      // // Force the toggled class to be removed when a collapsible nav link is clicked
      $(".navbar-sidenav .nav-link-collapse").click(function (e) {
        e.preventDefault();
        $(".body").removeClass("sidenav-toggled");
      });
      // // Prevent the content wrapper from scrolling when the fixed side navigation hovered over
      $(
        ".body.fixed-nav .navbar-sidenav, .body.fixed-nav .sidenav-toggler, .body.fixed-nav .navbar-collapse"
      ).on("mousewheel DOMMouseScroll", function (e) {
        var e0 = e.originalEvent,
          delta = e0.wheelDelta || -e0.detail;
        this.scrollTop += (delta < 0 ? 1 : -1) * 30;
        e.preventDefault();
      });
      // // Scroll to top button appear
      $(document).scroll(function () {
        var scrollDistance = $(this).scrollTop();
        if (scrollDistance > 100) {
          $(".scroll-to-top").fadeIn();
        } else {
          $(".scroll-to-top").fadeOut();
        }
      });
      // Configure tooltips globally
      $('[data-toggle="tooltip"]').tooltip();
      // Smooth scrolling using jQuery easing
      $(document).on("click", "a.scroll-to-top", function (event) {
        var $anchor = $(this);
        $("html, .body")
          .stop()
          .animate(
            {
              scrollTop: $($anchor.attr("href")).offset().top,
            },
            1000,
            "easeInOutExpo"
          );
        event.preventDefault();
      });
    });
  }, []);

  const location = useLocation();
  // console.log(location)
  const emailState = location.state.email;
  const email =
    typeof emailState != "object"
      ? emailState
      : emailState
      ? emailState
      : props.email;
  const [studentId, setStudentId] = useState();
  const [studentData, setStudentData] = useState({});
  const [subjects, setSubjects] = useState([]);
  const getSubjectsData = async () => {
    const url = `/subjectsData`;
    axios
      .get(url, {
        params: {
          email: email,
        },
      })
      .then((res) => {
        const data = res.data;
        setStudentId(data[0].studentId);
        setSubjects(data);
        setStudentData(data[0].studentData);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getSubjectsData();
  }, []);
  function subjectList() {
    return subjects.map((subjectData) => (
      <li key={subjectData.key} className="nav-item" data-placement="right">
        <Link
          key={subjectData.key}
          to={"/subjects?sid=" + subjectData.key}
          state={{
            email: email,
            studentId: studentId,
            studentData: studentData,
          }}
          className="nav-link"
          href="charts.html"
        >
          <div className="nav-item-list">
            <div
              style={{ color: subjectData.color }}
              className="nav-icon"
              dangerouslySetInnerHTML={{ __html: subjectData.icon }}
            />
            <span className="nav-link-text">{subjectData.name}</span>
          </div>
        </Link>
      </li>
    ));
  }
  function mobileMenuClick() {
    $("#navbarResponsive").toggleClass("show");
  }

  return (
    <div id="body" className="body fixed-nav sticky-footer bg-dark">
      <nav className="navbar  bg-light navbar-expand-lg fixed-top" id="mainNav">
        <Link
          className="navbar-brand"
          to={"/studentDashboard"}
          state={{
            email: email,
            studentId: studentId,
            studentData: studentData,
          }}
        >
          <img src={logo} alt="logo" height="40" />
        </Link>
        <button
          onClick={mobileMenuClick}
          className="navbar-toggler navbar-toggler-right"
          type="button"
          data-toggle="collapse"
          data-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav navbar-sidenav" id="exampleAccordion">
            <li className="nav-item" data-placement="right" title="Dashboard">
              <Link
                className="nav-link"
                to={"/studentDashboard"}
                state={{
                  email: email,
                  studentId: studentId,
                  studentData: studentData,
                }}
              >
                <i
                  className="fa fa-bookmark"
                  style={{ color: "rgb(11 0 140 / 89%)" }}
                ></i>
                <span className="nav-link-text">Dashboard</span>
              </Link>
            </li>
            {subjectList()}
            <li className="nav-item" data-placement="right" title="Test">
              <Link
                className="nav-link"
                to={"/mockTest"}
                state={{
                  email: email,
                  studentId: studentId,
                  studentData: studentData,
                }}
              >
                <i
                  className="fa fa-hourglass-2"
                  style={{ color: "#ff1178" }}
                ></i>
                <span className="nav-link-text">Test Series</span>
              </Link>
            </li>
            <li className="nav-item" data-placement="right" title="Reports">
              <Link
                className="nav-link"
                to={"/viewReport"}
                state={{
                  email: email,
                  studentId: studentId,
                  studentData: studentData,
                }}
              >
                <i
                  className="	fa fa-graduation-cap"
                  style={{ color: "#2c871e" }}
                ></i>
                <span className="nav-link-text">My Reports</span>
              </Link>
            </li>
            <li className="nav-item" data-placement="right" title="Reports">
              <Link
                className="nav-link"
                to={"/myProfile"}
                state={{
                  email: email,
                  studentId: studentId,
                  studentData: studentData,
                }}
              >
                <i
                  className="fa fa-user-circle"
                  style={{ color: "rgb(249 162 61)" }}
                ></i>
                <span className="nav-link-text">My Profile</span>
              </Link>
            </li>
            {/* <li className="nav-item" data-placement="right" title="Reports">
              <Link
                className="nav-link"
                to={"/privacy-policy"}
                state={{
                  email: email,
                  studentId: studentId,
                  studentData: studentData,
                }}
              >
                <i className="fa fa-expeditedssl"></i>
                <span className="nav-link-text">Privacy policy</span>
              </Link>
            </li> */}
            <li className="nav-item" data-placement="right" title="Reports">
              <Link
                className="nav-link"
                to={"/bank-details"}
                state={{
                  email: email,
                  studentId: studentId,
                  studentData: studentData,
                }}
              >
                <i className="fa fa-expeditedssl"></i>
                <span className="nav-link-text">Bank Details</span>
              </Link>
            </li>
          </ul>

          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <a
                href={"/"}
                className="nav-link"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                <i className="fa fa-fw fa-sign-out"></i>Logout
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default Nav;